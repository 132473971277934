import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/home/Home';
import Login from './components/login/Login';
import PrivateRoutes from './components/Auth'
import Nav from './components/navbar/Nav';




function App() {

  return (
   <HashRouter>
    <Nav/>
      <Routes>
        <Route element={<PrivateRoutes/>}>
           
          <Route path='/data' element={<Home/>}/>
        </Route>

        <Route path='/' element={<Login/>}/>
       
      </Routes>
    </HashRouter>
  );
}

export default App;
