import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './home.css'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import {FaFileInvoice} from 'react-icons/fa'

const Home = () => {

  const [modalada, setModalada] = useState(false);
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState();
  const [transData, setTransData] = useState([])
  const [kurirData, setKurirData] = useState([])
  const [noResi, setNoResi] = useState('')
  const [ongkir, setOngkir] = useState(0)
  const [kurir, setKurir] = useState(0)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const formatter = new Intl.NumberFormat('id', {
    style:'currency',
    currency:'IDR',
    maximumFractionDigits: 0
  })

  const getData = () => {
    axios.get(process.env.REACT_APP_GET_DATA ,{
    headers: {
      'token': localStorage.getItem("token")
    }
  }).then((res)=>{
    setTransData(res.data.data)
  })
}

  const getKurir = () => {
    axios.get(process.env.REACT_APP_GET_EXPEDITION).then((res)=>{
      setKurirData(res.data.data)
  })
  }   

  const getBukti = (transaction,e) => {
    console.log(transaction.id)
    axios.post(`${process.env.REACT_APP_GET_BUKTI}${transaction.id}`,{},
     {
      headers : {
      'token' : localStorage.getItem('token'),
      'Content-Type' : 'application/x-www-form-urlencoded'
      }
    }, 
    ).then((res)=>{
      window.scrollTo(0,0);
      setModalada(!modalada)
      setImage(res.data.data)
    }).catch(error =>{ 
    window.scrollTo(0,0);
      setImage('nein')
      setModal(!modal)})
  }

  useEffect(()=>{
      getData();
      getKurir();
      
    },[])

  const handleAccept = (transaction,e ) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_APPROVE}${transaction.id}`,{}, {
      headers: {
        'token': localStorage.getItem("token"),
        'accept':"*/*",
        'content-type': 'text/json'
      }
    }).then((res)=>{
     
      getData();
    })
    
  }

  const handleReject =  (transaction,e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_REJECT}${transaction.id}`,{}, {
      headers: {
        'token': localStorage.getItem("token"),
        'accept':"*/*",
        'content-type': 'text/json'
      }
    }).then((res)=>{
     
      getData();
    })
    
  }

  const handleDelivered =  (transaction,e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_DELIVERED}${transaction.id}`,{}, {
      headers: {
        'token': localStorage.getItem("token"),
        'accept': "*/*",
        'content-type': 'text/json'
      }
    }).then((res)=>{
      
      getData();
    })
    
  }

  const handleInput =  (transaction,e) => {
    e.preventDefault()
    axios.post(process.env.REACT_APP_INPUT_RESI,{
      resi: noResi,
      kurir: kurir,
      ongkir: ongkir ,
      id: transaction.id }, {
      headers: {
        'token': localStorage.getItem("token"),
        'accept':"*/*",
        'Content-Type': 'application/x-www-form-urlencoded',
        
      }
    }).then((res)=>{
      
      getData();
    })
    
  }
  
  const handleInvoice =  (transaction) => {
    
    axios.get(`${process.env.REACT_APP_GET_PDF}${transaction.invoice_number}`,  {responseType: "blob"}).then((res)=>{
      const blob = new Blob([res.data], {type: 'application/pdf'})
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement('a')
      link.href = blobURL
      window.open(link)
        })       
  }

  return (
    <div className='home'>
      {modalada == true && (
        <div className="modals">
        <div onClick={()=>setModalada(!modalada)} className="overlay"></div>
        <div className="modal-content">
          {image.map((img)=>{
            return (
              <img src={`https://ecgobikeid.com/${img.link}`}/>
            )
          })}
        </div>
      </div>
      )}

    {modal == true && (
        <div className="modals">
        <div onClick={()=>setModal(!modal)} className="overlay"></div>
        <div className="modal-content">
          <div className='modal-right'>
            <h1>ALERT</h1>
          </div>
          <div className='modal-left'>
            <h2>Belum upload bukti!</h2>
          </div>
        </div>
      </div>
      )

      }

      <h1 style={{fontSize:'50px'}}>ECGO PAYMENT SYSTEM</h1>

      <Form >
        <InputGroup className='my-3'>
          <Form.Control
            onChange={(e)=>setSearch(e.target.value)}
            placeholder="Pencarian"
          />
        </InputGroup>
      </Form>

      <Table striped bordered hover>
      <thead>
        <tr>
          <th className='tid'>Transaction ID</th>
          <th>No Invoice</th>
          <th>Amount</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
      {transData.filter((trans)=> {
        return (
          search.toLowerCase() === '' 
          ? trans
          : trans.invoice_number?.includes(search)
        )
      }
          ).map((transaction)=>{
            return(
              <>
              <tr key={transaction.id}> 
                <td>{transaction.id}</td>
                <td>{transaction.invoice_number} <FaFileInvoice onClick={(e)=> handleInvoice(transaction)} style={{cursor:"pointer"}}/></td>
                <td>{formatter.format(transaction.total_price)}</td>
                <td>{transaction.status}</td>

                 
                <td>
                  {transaction.status === "PENDING" && <>
                    <button onClick={(e)=> handleAccept(transaction,e)} style={{background:'#36d636'}}>Approve</button>
                    <button onClick={(e)=> handleReject(transaction,e)} style={{background:'#ff3838'}}>Reject</button>
                    </>}
                  {transaction.status === 'On Delivery' && <>
                    <button onClick={(e)=> handleDelivered(transaction,e)}>Delivered</button>
                  </>
                  }
                </td>

                <td>
                  {transaction.id && <>
                    <button onClick={(e)=> getBukti(transaction,e)}>Bukti</button>
                  </>}
                </td>

                    <td className='resi-tabel'>
                        {(transaction.status === 'PROCESSED') &&<>
                        <input  onChange={(e)=> setNoResi(e.target.value)} placeholder='Masukkan No Resi'/>
                          <select onChange={(e) => setKurir(e.target.value)}>
                            <option>Pilih Kurir</option>
                            {kurirData.map((kurir)=>{
                              return(
                                <option value={kurir.id}>{kurir.name}</option>
                              )
                            })}
                          </select>
                          <input  onChange={(e)=> setOngkir(e.target.value)} className='biaya-input'  placeholder='Jumlah Ongkir'/>
                          <button  onClick={(e)=> handleInput(transaction,e)}>Input</button>
                            </>}  

                       
                    </td>


              </tr>
              </>
            )
          })}
      </tbody>
      </Table>


      <table>
        <tr>
          
        </tr>
          
          
        
        
       
       </table> 
    </div>
  )
}

export default Home

