import React from 'react'
import { useNavigate } from 'react-router-dom'
import './navbar.css'

const Nav = () => {

    let navigate = useNavigate()
    function handleClick(e){
        e.preventDefault()
        localStorage.removeItem("token")
        navigate('/')
    }

  return (
    <div className='nav'>
        {localStorage.getItem("token") !== null && <button onClick={handleClick}>Logout</button>}

         
    </div>
  )
}

export default Nav