import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './login.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Login = () => {

  let Navigate =  useNavigate()
  const [token, setToken] = useState('')  
  const [formData, setFormData] = useState({
    password:'', 
    email:''
  })

  useEffect(()=>{
    localStorage.getItem('token') && 
    Navigate('/data')
  })

  function handleChange(e) {
    let data = {...formData}
    data[e.target.name]= e.target.value; 
    setFormData(data);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let newData= {email: formData.email, password:formData.password};
    axios.post(process.env.REACT_APP_LOGIN, newData).then(res => {
      setToken(res.data.token)
      localStorage.setItem('token',res.data.token)
      Navigate('/data')
    })
   

  }

  return (
    <div className='login'>
      
      <Form>
      <Form.Group className="mb-3" >
        <Form.Label>Email address</Form.Label>
        <Form.Control name='email' type="email" placeholder="Enter email" onChange={handleChange}/>
       
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control name='password' type="password" placeholder="Password" onChange={handleChange}/>
      </Form.Group>
      <Form.Group className="mb-3" >
        
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>









    </div>
  )
}

export default Login